import { AlertField } from "@/components/ui/parts";

export function PhoneCallVerficationAlert() {
  return (
    <AlertField
      type="notice"
      maw={500}
      title="info"
      style={{ whiteSpace: "pre-wrap" }}
    >
      ※ 表示された電話番号にかけて認証します。
      <br />
      ※ 表示されている秒数以内に登録された電話番号から電話をおかけ下さい。
      <br />
      ※ 発信者番号を通知する設定で電話をおかけ下さい。（非通知は認証できません）
      <br />
      ※
      認証に利用する電話番号は必ずご本人が音声応答可能な電話番号をご利用下さい。
      <br />
      以上をご確認の上、解決できない場合は管理者にご連絡ください。
      <br />
      <br />
      フリーダイヤル：
      <a type="tel" href="tel:0120-327-002">
        0120-327-002
      </a>
      <br />
      問合せメールアドレス：
      <a href="mailto:support@care-link.works">support@care-link.works</a>
    </AlertField>
  );
}

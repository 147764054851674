"use client";
import Script from "next/script";

// グローバル型定義
declare global {
  interface Window {
    $: any; // jQueryの型
    jQuery: any; // jQueryの型
    jQueryLoaded?: boolean; // jQueryが読み込まれたかのフラグ
    call_auth_run: (phoneNumber: string) => void;
  }
}

export function PhoneCallAuthLayout({
  children,
  baseUrl,
  callAuthId,
}: {
  children: React.ReactNode;
  baseUrl: string;
  callAuthId: string;
}) {
  // 完全なURLを構築
  const completeUrl = new URL("/verification/call/complete", baseUrl);
  const backUrl = new URL("/signup", baseUrl);
  // URLをエンコード
  const encodedCompleteUrl = encodeURIComponent(completeUrl.toString());
  const encodedBackUrl = encodeURIComponent(backUrl.toString());

  // スクリプトのURLを構築
  const scriptUrl = `https://asp.ostiaries.jp/api/2.0/s-callauth?id=${callAuthId}&ok_url=${encodedCompleteUrl}&back_url=${encodedBackUrl}`;

  return (
    <>
      {children}
      <Script
        src="https://code.jquery.com/jquery-3.7.1.js"
        integrity="sha256-eKhayi8LEQwp4NKxN+CfCh+3qOVUtJn3QNZ0TciWLP4="
        crossOrigin="anonymous"
        strategy="afterInteractive"
        onReady={() => {
          console.log("jQuery loaded:", typeof window.$ !== "undefined");
          // jQueryが読み込まれたことをグローバル変数に記録
          window.jQueryLoaded = true;
        }}
      />
      <Script
        src={scriptUrl}
        strategy="lazyOnload"
        onError={(e) => {
          console.error("Script load error:", e);
        }}
        onReady={() => {
          console.log("Call Auth script loaded");
        }}
      />
    </>
  );
}

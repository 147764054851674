import Image from "next/image";

import { Text } from "@/components/ui/elements";

import classes from "./_css/CampaignBadge.module.css";

type Props = {
  amount: number;
};

export function CampaignLabel({ amount }: Props) {
  return (
    <div className={classes.container}>
      <Image
        src="/images/icon/badge.png"
        alt="キャンペーン"
        width={20}
        height={20}
        className={classes.badge}
      />
      <div className={classes.textContainer}>
        <Text className={classes.text}>【限定・締切間近！】</Text>
        <Text className={classes.text}>
          アマゾンギフト券{amount}円プレゼント！
        </Text>
      </div>
    </div>
  );
}

import { useMutation } from "@apollo/client";

import { Button, HeartIcon, HeartOutlineIcon } from "@/components/ui/elements";

import { infoMessage } from "@/i18n";
import { showNotification } from "@/util";

import { followOfficeMutation, unfollowOfficeMutation } from "./_graphql";

type Props = {
  clientOfficeId: string;
  isFavorite: boolean;
};

export function OfficeFollowButton({ clientOfficeId, isFavorite }: Props) {
  const [addMutation, { loading: addLoading }] = useMutation(
    followOfficeMutation,
    {
      refetchQueries: [
        "Bookmarks",
        "JobOfferFeed",
        "JobOffer",
        "OfficeJobOfferList",
      ],
    },
  );
  const [removeMutation, { loading: removeLoading }] = useMutation(
    unfollowOfficeMutation,
    {
      refetchQueries: [
        "Bookmarks",
        "JobOfferFeed",
        "JobOffer",
        "OfficeJobOfferList",
      ],
    },
  );

  const handleAddFavorite = async () => {
    await addMutation({
      variables: {
        input: {
          clientOfficeId: clientOfficeId,
        },
      },
      onError: (error) =>
        showNotification({
          type: "error",
          message: error.message,
        }),
      onCompleted: (data) => {
        const res = data.followOffice;
        if (!res) return;
        showNotification({
          type: "info",
          message: infoMessage("addOfficeFavorite"),
        });
      },
    });
  };
  const handleremoveFavorite = async () => {
    await removeMutation({
      variables: {
        input: {
          clientOfficeId: clientOfficeId,
        },
      },
      onError: (error) =>
        showNotification({
          type: "error",
          message: error.message,
        }),
      onCompleted: (data) => {
        const res = data.unfollowOffice;
        if (!res) return;
        showNotification({
          type: "info",
          message: infoMessage("removeOfficeFavorite"),
        });
      },
    });
  };
  const isLoading = addLoading || removeLoading;
  return (
    <Button
      variant="outline"
      size="sm"
      loading={isLoading}
      onClick={isFavorite ? handleremoveFavorite : handleAddFavorite}
      rightSection={
        isFavorite ? (
          <HeartIcon color="red" size="1.5em" />
        ) : (
          <HeartOutlineIcon size="1.5em" />
        )
      }
    >
      {isFavorite ? "お気に入り解除" : "施設をお気に入りする"}
    </Button>
  );
}

import { useForm } from "react-hook-form";

import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  Button,
  Card,
  Center,
  Group,
  H2,
  Rating,
  Stack,
  Text,
} from "@/components/ui/elements";
import { RatingField } from "@/components/ui/parts";

import { infoMessage } from "@/i18n";
import { showNotification } from "@/util/userNotifications";

import { reviewTemporaryJobOfferMutation } from "../_graphql";

const schema = z.object({
  healthRate: z.number(),
  atmosphereRate: z.number(),
  beginOnTimeRate: z.number(),
  differenceInjobOfferRate: z.number(),
});

type Schema = z.infer<typeof schema>;

type Props = {
  close: () => void;
  temporaryJobOfferId: string;
  onReviewComplete: (average: number) => void;
};

export function Review(props: Props) {
  const { close, temporaryJobOfferId, onReviewComplete } = props;
  const { control, watch, handleSubmit } = useForm<Schema>({
    defaultValues: {
      healthRate: 5,
      atmosphereRate: 5,
      beginOnTimeRate: 5,
      differenceInjobOfferRate: 5,
    },
    resolver: zodResolver(schema),
  });
  const values = watch([
    "healthRate",
    "atmosphereRate",
    "beginOnTimeRate",
    "differenceInjobOfferRate",
  ]);
  const [mutation, { loading }] = useMutation(reviewTemporaryJobOfferMutation, {
    refetchQueries: ["ReviewRequests"],
  });
  const average = values.reduce((acc, num) => acc + num, 0) / values.length;
  const onSubmit = async (data: Schema) => {
    await mutation({
      variables: {
        input: {
          temporaryJobOfferId: temporaryJobOfferId,
          atmosphereRate: data.atmosphereRate,
          beginOnTimeRate: data.beginOnTimeRate,
          differenceInJobOfferRate: data.differenceInjobOfferRate,
          healthRate: data.healthRate,
        },
      },
      onError: (error) =>
        showNotification({
          type: "error",
          message: error.message,
        }),
      onCompleted: (data) => {
        const res = data.reviewClientOffice;
        if (!res) return;
        showNotification({
          type: "info",
          message: infoMessage("addReview"),
        });
        onReviewComplete(average);
      },
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="lg">
        <H2>勤務を完了します。クライアントのレビューをしてください。</H2>
        <Card mx="md">
          <Stack>
            <div>
              <Text fz="md">綺麗さ・衛生面はよかったか</Text>
              <Group align="center">
                <Text fw="bold" fz="xl">
                  {values[0].toFixed(1)}
                </Text>
                <RatingField
                  fractions={2}
                  size="lg"
                  control={control}
                  name="healthRate"
                />
              </Group>
            </div>
            <div>
              <Text fz="md">施設の雰囲気はよかったか</Text>
              <Group align="center">
                <Text fw="bold" fz="xl">
                  {values[1].toFixed(1)}
                </Text>
                <RatingField
                  fractions={2}
                  size="lg"
                  control={control}
                  name="atmosphereRate"
                />
              </Group>
            </div>
            <div>
              <Text fz="md">予定時間通りに業務が開始されたか</Text>
              <Group align="center">
                <Text fw="bold" fz="xl">
                  {values[2].toFixed(1)}
                </Text>
                <RatingField
                  fractions={2}
                  size="lg"
                  control={control}
                  name="beginOnTimeRate"
                />
              </Group>
            </div>
            <div>
              <Text fz="md">求人内容と実際の業務に差異はなかったか</Text>
              <Group align="center">
                <Text fw="bold" fz="xl">
                  {values[3].toFixed(1)}
                </Text>
                <RatingField
                  fractions={2}
                  size="lg"
                  control={control}
                  name="differenceInjobOfferRate"
                />
              </Group>
            </div>
          </Stack>
        </Card>
        <Center>
          <Text variant="center" fz="lg" fw="bold" pr="md">
            総合評価：{average.toFixed(2)}
          </Text>
          <Rating size="xl" value={average} readOnly fractions={4} />
        </Center>
        <Group justify="center">
          <Button variant="white" onClick={close}>
            閉じる
          </Button>
          <Button loading={loading} type="submit">
            レビュー送信
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

import Image from "next/image";
import Link from "next/link";

import {
  TimeFiveIcon,
  Card,
  Group,
  Badge,
  Stack,
  H1,
  Text,
} from "@/components/ui/elements";
import { IconLabel } from "@/components/ui/parts";

import { EmployedTemporaryJobOffersFieldsFragment } from "@/gql/__generated__/graphql";

import classes from "./_css/WorkScheduleCard.module.css";
import { JobTypeBadge } from "./JobTypeBadge";

type Props = {
  jobOffer: EmployedTemporaryJobOffersFieldsFragment;
};

export function WorkScheduleCard({ jobOffer }: Props) {
  const {
    workCondition,
    workConditionStatus,
    temporaryJobOffer,
    image,
    jobCategory,
    postedClientOffice,
    businessCategory,
  } = jobOffer;
  const noimage =
    "https://placehold.jp/30/dd6699/ffffff/300x150.png?text=placeholder+image";
  const StatusBadge = () => {
    if (workConditionStatus) {
      if (workConditionStatus === "scheduled_to_work")
        return (
          <Badge color="blue" className={classes.badge}>
            勤務予定
          </Badge>
        );
      if (workConditionStatus === "requesting_correction") {
        return (
          <Badge color="red" className={classes.badge}>
            修正依頼中
          </Badge>
        );
      }
      if (workConditionStatus === "requesting_denied") {
        return (
          <Badge color="red" className={classes.badge}>
            修正依頼を再提出してください
          </Badge>
        );
      }
      if (workConditionStatus === "at_work") {
        return (
          <Badge variant="light" color="green" className={classes.badge}>
            勤務時間中
          </Badge>
        );
      }
      if (workConditionStatus === "work_has_not_yet_started") {
        return (
          <Badge color="green" className={classes.badge}>
            出勤時間を過ぎています
          </Badge>
        );
      }
      if (workConditionStatus === "work_not_completed_yet") {
        return (
          <Badge color="green" className={classes.badge}>
            退勤時間を過ぎています
          </Badge>
        );
      }
      if (workConditionStatus === "work_completed") {
        return (
          <Badge color="dark" className={classes.badge}>
            完了
          </Badge>
        );
      }
    }
    return (
      <Badge color="blue" className={classes.badge}>
        勤務予定
      </Badge>
    );
  };
  return (
    <>
      <Card
        component={Link}
        href={`/workSchedule/${temporaryJobOffer.id}`}
        withBorder
        shadow="lg"
        radius="md"
        p="md"
        className={classes.card}
      >
        <Card.Section className={classes.image} h={250}>
          <StatusBadge />
          <Image
            src={image.url ? image.url : noimage}
            alt={jobCategory.name}
            fill
          />
        </Card.Section>

        <Card.Section className={classes.section} mt="md">
          <Stack gap="sm">
            <JobTypeBadge businessCategoryName={businessCategory.name} />
            <Group justify="space-between">
              <H1>{temporaryJobOffer.title}</H1>
            </Group>
            <Group justify="space-between">
              <Group>
                <IconLabel
                  icon={<TimeFiveIcon />}
                  label={workCondition.formatWorkDate}
                  labelProps={{ fz: "sm" }}
                />
              </Group>
            </Group>
            <Group justify="space-between">
              <Text fz="md" fw={800} c="teal.8">
                {postedClientOffice.name}
              </Text>
            </Group>
          </Stack>
        </Card.Section>
      </Card>
    </>
  );
}
